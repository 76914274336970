@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #020011 inset !important;
  }
  scrollbar-width: thin;
  scrollbar-color: #6bddf5 #0f0f1c;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

/* customize scrollbar */
/* Customizing the scrollbar globally */
*::-webkit-scrollbar {
  width: 12px; /* Width of the vertical scrollbar */
  height: 12px; /* Height of the horizontal scrollbar */
}

*::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

*::-webkit-scrollbar-thumb {
  background: #888; /* Handle color */
}

*::-webkit-scrollbar-thumb:hover {
  background: #555; /* Handle color on hover */
}

/* body {
  background-color: #020011;
} */

/* custom card styles */
/* .card-group {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.card {
  position: relative;
  height: 270px;
  width: 450px;
  border-radius: 24px;
  background: #2c3e50;
  backdrop-filter: blur(30px); /* This will produce the blur */
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.logo img,
.chip img,
.number,
.name,
.from,
.to,
.ring {
  position: absolute;
}

.logo img {
  top: 10px;
  right: 20px;
  width: 80px;
  height: auto;
  opacity: 0.8;
}

.chip img {
  top: 120px;
  left: 40px;
  width: 50px;
  height: auto;
  opacity: 0.8;
}

.number,
.name,
.from,
.to {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  letter-spacing: 2px;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
}

.number {
  left: 40px;
  bottom: 65px;
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
}

.name {
  font-size: 0.75rem;
  left: 40px;
  bottom: 35px;
}

.from {
  font-size: 0.5rem;
  bottom: 35px;
  right: 110px;
}

.to {
  font-size: 0.75rem;
  bottom: 35px;
  right: 40px;
}

/* ring effect */
.ring {
  height: 500px;
  width: 500px;
  border-radius: 50%;
  background: transparent;
  border: 50px solid rgba(255, 255, 255, 0.1);
  bottom: -250px;
  right: -250px;
  box-sizing: border-box;
}

.ring::after {
  content: "";
  position: absolute;
  height: 600px;
  width: 600px;
  border-radius: 50%;
  background: transparent;
  border: 30px solid rgba(255, 255, 255, 0.1);
  bottom: -80px;
  right: -110px;
  box-sizing: border-box;
}
